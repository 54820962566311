
import { getActivityInfo, getActivitySaleInfo } from './../editOredit/api'
export default {
  name: "",
  data() {
    return {
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },
      {
        path: "/storeActivities",
        name: "storeActivities",
        title: "专卖店市场活动"
      },
      {
        path: "/storeActivities/activityView",
        name: "activityView",
        title: "活动查看"
      }
      ],
      pageLoadFlag: false,
      detilInfo: {
      },
      columns: [
        {

          title: "物料组",
          dataIndex: "matklName",
          width: 400,
        },
        {
          title: "预计销售额(万元)",
          dataIndex: "planSaleMoney",
          width: 400,
        },
        {
          title: "实际销售额(万元)",
          dataIndex: "realSaleMoney",
          width: 400,
        },
        {
          title: "实际销量(台)",
          dataIndex: "realSaleSum",
          width: 400,
        },
        {
          title: "分公司提报销售额(实际)(元)",
          dataIndex: "submitSales",
          width: 400,
        },
      ],
      tableData: [],


      activefileList: [], // 活动方案附件
      fileList: [], // 认筹附件
      saleList: [], // 销售附件
      shareList: [] // 分享附件
    };
  },


  mounted() {
    if (this.$route.query.id) {
      this.getDetailInfo(this.$route.query.id)
      this.getOwner(this.$route.query.id)
    } else {
      this.$message.warning('获取详情失败')
    }
  },

  methods: {
    getOwner(id) {
      let data = {
        id: id
      }
      getActivitySaleInfo(data).then(res => {
        if (res && res.data.code == 0) {
          this.tableData = res.data.data
        } else {
          this.$message.warning('获取数据失败', res.data.msg)
        }
      })
    },
    // 下载
    handleRemove(item) {
      window.open(item.url, '_blank')
    },
    // 获取详情
    getDetailInfo(aid) {
      let data = { id: aid }
      getActivityInfo(data).then(res => {
        if (res.data.code == 0) {
          this.detilInfo = res.data.data

          // 活动方案附件
          if (res.data.data.hdfaFile) {
            this.activefileList.push(res.data.data.hdfaFile)
          }
          let aaa = []
          if (this.activefileList && res.data.data.hdfaFile) {
            this.activefileList.forEach(item => {
              aaa.push(
                {
                  uid: item.id,
                  name: item.attachName,
                  status: "done",
                  url: item.attachPath,
                },
              );
            })
          }
          this.activefileList = aaa

          // 认筹附件
          if (res.data.data.rcxcFile) {
            this.fileList.push(res.data.data.rcxcFile)
          }

          let bbb = []
          if (this.fileList && res.data.data.rcxcFile) {
            this.fileList.forEach(item => {
              bbb.push(
                {
                  uid: item.id,
                  name: item.attachName,
                  status: "done",
                  url: item.attachPath,
                },
              );
            })
          }
          this.fileList = bbb

          // 销售附件
          if (res.data.data.xsxcFile) {
            this.saleList.push(res.data.data.xsxcFile)
          }

          let ccc = []
          if (this.saleList && res.data.data.xsxcFile) {
            this.saleFileId = res.data.data.xsxcFile.id
            this.saleList.forEach(item => {
              ccc.push(
                {
                  uid: item.id,
                  name: item.attachName,
                  status: "done",
                  url: item.attachPath,
                },
              );
            })
          }
          this.saleList = ccc
          // 经验
          if (res.data.data.jyfxFile) {
            this.shareList.push(res.data.data.jyfxFile)
          }

          let ddd = []
          if (this.shareList && res.data.data.jyfxFile) {
            this.shareFileId = res.data.data.jyfxFile.id
            this.shareList.forEach(item => {
              ddd.push(
                {
                  uid: item.id,
                  name: item.attachName,
                  status: "done",
                  url: item.attachPath,
                },
              );
            })
          }
          this.shareList = ddd

        } else {
          this.$message.warning('获取详情失败', res.data.msg)
        }


      })
    },
    goback() {
      window.close()
      this.$router.push({ path: '/agentVerificationActivities' });
    }
  }
};


import http from "@/utils/request";



  
export function getDLlnumSeach(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/getAgent.nd",
        data
    });
}





export function activityApplication(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/flowStart.nd",
        data,
        // contentType: "application/x-www-form-urlencoded",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
    });
}

// 查看单条数据进行编辑 /custAgentActivity/getActivityById.nd
export function getActivityInfo(data) {
    return http({
        method: "post",
        url: "/specialShopActivity/getActivityById.nd",
        data
    });
}
// 查询物料组

export function getMatklByShop(data) {
    return http({
        method: "post",
        url: "/specialShopActivity/getMatklByShop.nd",
        data
    });
}

// 查询传给后台的物料组
export function getActivitySaleInfo(data) {
    return http({
        method: "post",
        url: "/specialShopActivity/getActivitySaleInfo.nd",
        data
    });
}
